<template>
  <v-dialog :value="true" persistent max-width="600px" min-width="360px">
    <div>
      <v-tabs show-arrows background-color="primary" icons-and-text dark grow>
        <v-tabs-slider color="primary darken-4"></v-tabs-slider>

        <v-tab>
          <v-icon large>mdi-account-outline</v-icon>
          <div class="caption py-1">Password Reset</div>
        </v-tab>

        <v-tab-item>
          <v-card v-if="loading" class="px-4">
            <LoadingBar />
          </v-card>
          <v-card v-else-if="success === true" class="px-4">
            <v-card-text>
              <v-row>
                <v-col class="text-center">
                  <p class="text-h6">
                    Request submitted successfully!
                  </p>
                  <p class="text-h6">
                    Check your inbox for further instructions.
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <template v-else>
            <div v-if="error !== false" class="error lighten-1">
              <p class="pa-5 text-center" v-html="error"></p>
            </div>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="form" v-model="valid" @submit.prevent="submit">
                  <v-row>
                    <template v-if="token">
                      <v-col cols="12">
                        <v-text-field
                          label="Password"
                          type="password"
                          v-model="password"
                          :rules="rules.password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Password (confirm)"
                          type="password"
                          v-model="password_confirm"
                          v-on:keyup.enter="submit"
                          :rules="rules.password_confirm"
                        ></v-text-field>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="12">
                        <v-text-field
                          label="Email"
                          v-model="username"
                          :rules="rules.email"
                        ></v-text-field>
                      </v-col>
                    </template>
                    <v-col class="d-flex" cols="12" sm="6"></v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-center" cols="12" sm="6">
                      <v-btn
                        x-large
                        block
                        :disabled="!valid"
                        color="primary"
                        @click="submit"
                      >
                        {{ token ? "Update Password" : "Reset Password" }}
                      </v-btn>
                      <router-link to="/login" class="d-block mt-2">
                        <v-btn
                          x-large
                          block
                          color="primary"
                          @click="submit"
                          style="text-decoration: none;"
                        >
                          Cancel
                        </v-btn>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </template>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-dialog>
</template>

<script>
import LoadingBar from "@/components/loaders/LoadingBar";
import { required, password, email } from "@/utils/rules";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapMutations } from "vuex";
import hasIn from "lodash.hasin";

export default {
  name: "PasswordReset",
  components: { LoadingBar },
  data() {
    return {
      valid: false,
      password: "",
      password_confirm: "",
      loading: false,
      error: false,
      success: null,
      rules: {
        email: [required, email],
        password: [password],
        password_confirm: [
          required,
          v => v === this.password || "Passwords do not match"
        ]
      }
    };
  },
  computed: {
    ...mapFields("auth", ["username"]),
    token() {
      const token = this.$route.params.token;
      return token !== undefined && token.length ? token : false;
    }
  },
  methods: {
    ...mapActions("auth", ["requestPasswordReset", "passwordReset"]),
    ...mapMutations("auth", ["SET_MESSAGE"]),
    submit() {
      if (this.$refs.form.validate()) {
        return this.token === false ? this.request() : this.reset();
      }
    },
    request() {
      this.loading = true;
      this.requestPasswordReset(this.username).then(res => {
        this.loading = false;
        if (res === true) {
          this.success = true;
          return;
        }
        if (hasIn(res, "data.message")) {
          this.error = res.data.message;
        } else {
          this.error = "Unknown Error - Please try again";
        }
      });
    },
    reset() {
      this.loading = true;
      this.passwordReset({
        token: this.token,
        password: this.password,
        password_confirm: this.password_confirm
      }).then(res => {
        this.loading = false;
        if (res === true) {
          this.SET_MESSAGE("Password Reset Successfully! You can now login");
          return this.$router.push("/login");
        }
        if (hasIn(res, "data.message")) {
          this.error = res.data.message;
        } else {
          this.error = "Unknown Error - Please try again";
        }
      });
    }
  }
};
</script>

<style scoped></style>
